import React from 'react'
import { useTranslation } from 'react-i18next';

export const getTranslationEntry = (text, namespace) => {
    return (
        <StateTranslationEntry text={text} ns={namespace}/>
    )
}

export const getStateTranslationEntry = (text) => {
    return (
        <StateTranslationEntry text={text} ns={"stateFilter"}/>
    )
}

const StateTranslationEntry = (props) => {
    const {t} = useTranslation();
    return props.ns ? t(props.ns + ":" + props.text) : t(props.text)
}