import React from 'react'
import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    OnlyFilterActionDisplayStrategy, 
    TableFieldDescription,
    StatusChipComponentCreator,
    ProcessControlTasksComponentCreator,
    DateTimeComponentCreator,
    DateTimeDisplayCreator,
} from '../../../components/BPMTable/TableMapperCreator'
import {ExportFooterItemCreator} from "../../../components/BPMTable/TableFooterItems"
import PopupContent from '../../../pages/Jobs/Process/PopupContent'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import { createStateChip } from '../../../utils/processControlUtils'

const tableQuery = gql`
    query AllProcessControls(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $state:String,
        $user:String,
        $createdAt:String,
        $updatedAt:String,
        $totalCount:String,
        $currentCount:String,
    ) {
        allProcessControls(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            state:$state,
            user:$user,
            createdAt:$createdAt,
            updatedAt:$updatedAt,
            currentCount:$currentCount,
            totalCount:$totalCount,
        ) {
            edges {
                node {
                    id
                    state
                    user {
                        id
                        email
                        enterpriseId
                    }
                    createdAt
                    updatedAt
                    totalCount
                    currentCount
                    modelClassName
                    message
                    subProcesses {
                        taskName
                        state
                        createdAt
                        updatedAt
                        duration
                        message
                        
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

let editMutator

let newMutator

let deleteMutator

const unitQuery = `
    processControl(id: $id) {
        id
        state
        user {
            id
            email
            enterpriseId
        }
        createdAt
        updatedAt
        totalCount
        currentCount
        modelClassName
        message
        subProcesses {
            taskName
            state
            createdAt
            updatedAt
            message    
        }
    }
`

const queries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class JobsProcessTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "JobsProcessTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries)
        tableCreator.setDisplayStrategy(new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        tableCreator.setInitialSortField('createdAt', 'desc')
        tableCreator.addDescriptions ([
            new TableFieldDescription(
                'user',
                new DataMapper("user.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("user")),
                {label: 'User'}
                
            ),
            new TableFieldDescription(
                'state',
                new DataMapper("state"),
                new f.FilterDescription(new f.ProcessControlStateFilter("state")),
                {label: 'State', componentCreator: new StatusChipComponentCreator()}
            ),
            new TableFieldDescription(
                'modelClassName',
                new DataMapper("modelClassName"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Table', hideSort: true}
            ),
            new TableFieldDescription(
                'createdAt',
                new DataMapper("createdAt"),
                new f.FilterDescription(new f.DateFilter("createdAt")),
                {
                    label: 'Creation Date',
                    componentCreator: new DateTimeComponentCreator(), 
                    excelDisplay: new DateTimeDisplayCreator()
                }
            ),
            new TableFieldDescription(
                'updatedAt',
                new DataMapper("updatedAt"),
                new f.FilterDescription(new f.DateFilter("updatedAt")),
                {
                    label: 'Updated at',
                    componentCreator: new DateTimeComponentCreator(), 
                    excelDisplay: new DateTimeDisplayCreator()
                }
            ),
            new TableFieldDescription(
                'totalCount',
                new DataMapper("totalCount"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Total count', hideSort: true}
            ),
            new TableFieldDescription(
                'currentCount',
                new DataMapper("id", "totalCount", "currentCount", "subProcesses"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Current count',
                    componentCreator: new ProcessControlTasksComponentCreator(PopupContent),
                    transform: new DataTransformer(({id, totalCount, currentCount, subProcesses}) => ({processControlId: id, total: totalCount, count: currentCount, tasks: subProcesses})),
                    hideSort: true,
                }
            ),
            new TableFieldDescription(
                'message',
                new DataMapper("message"),
                new f.FilterDescription(new f.TextFilter('message')),
                {label: 'Message', onlyForm: true}
            ),
        ]);

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(JobsProcessTableCreator)

export default JobsProcessTableCreator;
