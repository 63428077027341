import React from 'react'
import { gql } from '@apollo/client'
import { 
  DataMapper,
  DataTransformer,
  NullClickStrategy, 
  OnlyFilterActionDisplayStrategy, 
  TableFieldDescription,
  ConsolidatedSBOChipComponentCreator,
  StatusChipComponentCreator,
  DateTimeComponentCreator,
  DateTimeDisplayCreator,
} from '../../components/BPMTable/TableMapperCreator'
import * as f from '../../components/BPMTable/BPMInputs'
import { IconButton } from '@material-ui/core'
import { Description as DescriptionIcon } from '@material-ui/icons'
import { useAxiosClient } from '../../contexts/Axios'
import {ExportFooterItemCreator} from "../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
query ConsolidatedSboReport($orderField:String,
  $first:Int,
  $last:Int,
  $after:String,
  $before:String,
  $firstName:String,
  $lastName:String,
  $enterpriseId:String,
  $createdAt:String,
  $state:String,
  ){
    consolidatedSboReport(
      orderField:$orderField,
      first:$first,
      last:$last,
      after:$after,
      before:$before,
      firstName:$firstName,
      lastName:$lastName,
      enterpriseId:$enterpriseId,
      createdAt:$createdAt,
      state:$state,
    ){
      edges{
        node{
          file
          state
          createdAt
          totalCount
          currentCount
          user {
            id
            firstName
            lastName
            enterpriseId
          }
          id
          vendors{
            edges{
              node{
                name
              }
            }
          }
          periods{
            edges{
              node{
                date
              }
            }
          }
        }
      }
    }
  }

`

const onClickDownloadErrors = (client, id) => {
  client.get(
      `billing/download/consolidatedSBO/get_info/${id}`,
  ).then(
      response => {
          window.location.assign(`${client.defaults.baseURL}/billing/download/consolidatedSBO/files/${response.data}`);
      }
  ).catch(
      error => console.log(error)
  )
}

const DownloadFile = props => {
  const { axiosClient } = useAxiosClient()
  return (
      <div key={`fileKey=${props.id}`} style={{textAlign:'center'}}>
      <IconButton onClick={ event => { event.stopPropagation(); onClickDownloadErrors(axiosClient, props.id)}}>
          <DescriptionIcon />
      </IconButton>
      </div>
  )
}

const queries = {tableQuery}

class ConsolidatedSBOResultsTablerCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ConsolidatedSBOResultsTablerCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);
        tableCreator.setInitialSortField('createdAt', 'desc')
        tableCreator.addDescriptions([
          new TableFieldDescription(
            'firstName',
              new DataMapper('user.firstName'),
              new f.FilterDescription(new f.TextFilter("firstName")),
              {label: 'User Name'}
        ),
        new TableFieldDescription(
            'lastName',
            new DataMapper('user.lastName'),
            new f.FilterDescription(new f.TextFilter("lastName")),
            {label: 'User Surname'}
        ),
        new TableFieldDescription(
            'enterpriseId',
            new DataMapper('user.enterpriseId'),
            new f.FilterDescription(new f.TextFilter("enterpriseId")),
            {label: 'User Id'}
        ),
        new TableFieldDescription(
            'filters',
            new DataMapper('vendors', 'periods'),
            new f.FilterDescription(new f.NullCreator()),
            {
              label: 'Applied Filters', hideSort: true,
              transform: new DataTransformer(({vendors, periods})=> ({periods:periods.edges.map(nodeItem => nodeItem.node.date), vendors:vendors.edges.map(nodeItem => nodeItem.node.name)})),
              componentCreator: new ConsolidatedSBOChipComponentCreator()
            }
        ),
        new TableFieldDescription(
          'createdAt',
          new DataMapper("createdAt"),
          new f.FilterDescription(new f.DateFilter("createdAt")),
          {
            label: 'Creation Date',
            componentCreator: new DateTimeComponentCreator(),
            excelDisplay: new DateTimeDisplayCreator()
          }
        ),
        new TableFieldDescription(
          'state',
          new DataMapper('state'),
          new f.FilterDescription(new f.ProcessControlStateFilter("state")),
          {label: 'State', componentCreator: new StatusChipComponentCreator()}
        ),
          new TableFieldDescription(
            'count',
            new DataMapper('totalCount', "currentCount"),
            new f.FilterDescription(new f.NullCreator()),
            {
              label: 'count', hideSort:true,
              transform: new DataTransformer(({totalCount, currentCount})=>`${currentCount} of ${totalCount}`)
            }
        ),
          new TableFieldDescription(
              'id',
              new DataMapper('id', 'state'),
              new f.FilterDescription(new f.NullCreator()),
              {
                label: 'File', hideSort:true,
                transform: new DataTransformer(({id, state})=> state === "FINISHED" ? <DownloadFile id={id}/> : null)
              }
        ),

        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ConsolidatedSBOResultsTablerCreator)

export default ConsolidatedSBOResultsTablerCreator;