import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import UserMinimalTable from '../../../pages/Inventory/User/MinimalTable'
import CostCenterMinimalTable from '../../../pages/Inventory/CostCenter/CostCenterMinimalTable'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { InventoryUserTableCreatorQueries } from './InventoryUserTableCreator'

const options = ['NORMAL', 'MANAGER', 'MANAGER_ADMIN']

const currencyQuery = `
    allCurrency {
        edges {
            node {
                id
                code
            }
        }
    }
`

const languagesQuery = `
    allLanguage {
        edges {
            node {
                id
                codename
            }
        }
    }
`;

const availableDomains = gql`query{
    availableDomains {
        name
        id
    }
}`;

const domainName = data => data.name
const domainTransformer = new DataTransformer(domains => domains ? domains : [], domains => domains.map(domain => domain.id), [])

class InventoryUserFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "InventoryUserFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'User', InventoryUserTableCreatorQueries)
        const userTablePicker = new UserMinimalTable({first: 15, orderDirection: "desc", orderField: "isActive",isActive:true,role:"Manager"}) 
        const costCenterTablePicker = new CostCenterMinimalTable({first: 15, orderDirection: "desc", orderField: "isActive",isActive:true})

        const generalSection = new FormSection('General Seccion', 1)
        const domainSection = new FormSection("Domains", 2)
        const managerSection = new FormSection("Manager", 3)
        const costCenterSection = new FormSection("Cost Center", 4)

        formCreator.addInputs([
            new FormFieldDescription(
                'enterpriseId',
                new f.InputDescription(new f.TextCreator("enterpriseId"), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Enterprise Id'}
            ),
            new FormFieldDescription(
                'firstName',
                new f.InputDescription(new f.TextCreator("firstName"), true, new f.RequiredValidator(1)),
                generalSection,
                2,
                {label: 'First Name'}
            ),
            new FormFieldDescription(
                'middleName',
                new f.InputDescription(new f.TextCreator("middleName"), false),
                generalSection,
                3,
                {label: 'Middle Name'}
            ),
            new FormFieldDescription(
                'lastName',
                new f.InputDescription(new f.TextCreator('lastName'), true, new f.RequiredValidator(1)),
                generalSection,
                4,
                {label: 'Last Name'}
            ),
            new FormFieldDescription(
                'role',
                new f.InputDescription(new f.OptionsDropDownCreator('role', options, true), true, new f.RequiredValidator()),
                generalSection,
                5,
                {label: 'Role', transform: new DataTransformer(data => data.charAt(0) + data.slice(1).toLowerCase())}
            ),
            new FormFieldDescription(
                'title',
                new f.InputDescription(new f.TextCreator("title"), false),
                generalSection,
                6,
                {label: 'Title'}
            ),
            new FormFieldDescription(
                'email',
                new f.InputDescription(new f.TextCreator('email'), true, new f.EmailValidator()),
                generalSection,
                7,
                {label: 'Email'}
            ),
            new FormFieldDescription(
                'dateHired',
                new f.InputDescription(new f.DateCreator('dateHired'), false),
                generalSection,
                8,
                {label: 'Date Hired'}
            ),
            new FormFieldDescription(
                'manager',
                new f.InputDescription(new f.ModelCreator('manager', userTablePicker), false),
                managerSection,
                9,
                {label: 'Manager',  transform: new DataTransformer(data=>data, data=> data)}
            ),
            new FormFieldDescription(
                'isActive',
                new f.InputDescription(new f.BoolCreator('isActive', true), false),
                generalSection,
                10,
                {label: 'Status'}
            ),
            new FormFieldDescription(
                'customAttr1',
                new f.InputDescription(new f.TextAreaCreator('customAttr1'), false),
                generalSection,
                11,
                {label: 'Custom Attribute 1'}
            ),
            new FormFieldDescription(
                'customAttr2',
                new f.InputDescription(new f.TextAreaCreator('customAttr2'), false),
                generalSection,
                12,
                {label: 'Custom Attribute 2'}
            ),
            new FormFieldDescription(
                'customAttr3',
                new f.InputDescription(new f.TextAreaCreator('customAttr3'), false),
                generalSection,
                13,
                {label: 'Custom Attribute 3'}
            ),
            new FormFieldDescription(
                'language',
                new f.InputDescription(new f.DropDownCreator("language"), true, new f.RequiredValidator(1)),
                generalSection,
                14,
                {label: 'Language', transform: new DataTransformer(data=> data.codename), queryAllDropdownOptions: languagesQuery}
            ),
            new FormFieldDescription(
                'currency',
                new f.InputDescription(new f.DropDownCreator("currency"), true, new f.RequiredValidator(1)),
                generalSection,
                15,
                {label: 'Currency', transform: new DataTransformer(data=> data.code), queryAllDropdownOptions: currencyQuery}
            ),
            new FormFieldDescription(
                'costCenter',
                new f.InputDescription(new f.ModelCreator('costCenter', costCenterTablePicker), false),
                costCenterSection,
                14,
                {label: 'Cost Center', transform: new DataTransformer(data=> data, data=> data)}
            ),
            new FormFieldDescription(
                'domains',
                new f.InputDescription(new f.MultiPickerCreator('domains', availableDomains, "availableDomains", [domainName], null, null, item=>item), false, new f.RequiredValidator(1)),
                domainSection,
                0,
                {label: 'Domains', transform: domainTransformer, prepareData: data => (data ? data.map(id => parseInt(id)) : [])}
            ),
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(InventoryUserFormCreator)

export default InventoryUserFormCreator