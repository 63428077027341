import React, { useState } from 'react'

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { onError } from "@apollo/client/link/error";

import MainLayout from './layouts/MainLayout/MainLayout'

import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux';

import publicRoutes from './routes/public'
import privateRoutes from './routes/private'
import { Init } from './components/Init/Init';
import Publici18n from './components/Init/Publici18n';
import { AuthContext } from './contexts/Auth'
import { axiosClientCreator, AxiosContext } from './contexts/Axios'
import { UserAuthorizer, UserContext } from './contexts/User'
import { createUploadLink } from 'apollo-upload-client'
import Portal from './components/Portal/Portal'
import SnackMessage from './components/BPMTable/TableComponents/messages/SnackMessage'
import { AddBPMApplicationMessages } from './redux/reducers/BPMReducer';

const App = (props) => {
    const { API_BASE_URL } = window['runtimeConfig']
    const GRAPHQL_PUBLIC_ENDPOINT = API_BASE_URL + "/graphql-pub"
    const GRAPHQL_PRIVATE_ENDPOINT = API_BASE_URL + "/graphql"
    var token = localStorage.getItem('auth_token');

    const dispatch = useDispatch();

    const [user, setUser] = useState({
        isAuthenticated: Boolean(token),
        authToken: JSON.parse(token) || '',
    })

    const authenticate = (token, from) => {
        localStorage.setItem('auth_token', JSON.stringify(token))
        setUser({
            isAuthenticated: true,
            authToken: token
        })
        props.history.push(from)
    }

    const signout = () => {
        localStorage.removeItem('auth_token');
        setUser({
            isAuthenticated: false,
            authToken: ''
        })

        window.location.href = '/';
    }

    const getAxiosInstance = () => {
        const axiosInstance = axiosClientCreator(user.authToken, API_BASE_URL);

        axiosInstance.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response){
                if ( error.response.data?.token?.KEY == 'ERR_EXPIRED_TOKEN' ) {
                    console.log("EXPIRED TOKEN!");
                    signout();
                }
                else if ( error.response.status == 401 ) {
                    console.log(error.response.statusText);
                    signout();
                }
            } else {
                console.log(error);
            }

            return Promise.reject(error);
        });

        return axiosInstance;
    }

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        let _errors = []
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
                const error = {
                    text: `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, 
                    variant: 'error', 
                }
                _errors.push(error)
            });
        }

        if (networkError) {
            if ( networkError.statusCode == 429 ) {
                const error = {
                    text: 'Account locked: too many login attempts. Please try again later.', 
                    variant: 'error', 
                }
                _errors.push(error)
            }
            else if ( networkError.statusCode == 401 ) {
                const error = {
                    text: 'Please provide valid credentials.', 
                    variant: 'error', 
                }
                _errors.push(error)
                signout();
            }
            else {
                const error = {
                    text: `[Network error]: ${networkError}`, 
                    variant: 'error', 
                }
                _errors.push(error)
            }
        }

        if (_errors.length > 0) {
            console.log(_errors);
            dispatch(AddBPMApplicationMessages(_errors))
        }
    });
  
    return (
        <AuthContext.Provider value={{user, authenticate, signout}} >
            {user.isAuthenticated ?
                <ApolloProvider 
                    client={
                        new ApolloClient({
                            cache: new InMemoryCache(),
                              //@ts-ignore
                            link: errorLink.concat(
                                createUploadLink({
                                    uri: GRAPHQL_PRIVATE_ENDPOINT,
                                    headers: {Authorization: `JWT ${user.authToken}`}
                                })
                            ),
                        })
                    }
                >
                    <AxiosContext.Provider value={{axiosClient: getAxiosInstance()}}>
                        <Init>
                            {data =>
                                <UserContext.Provider value={{authorizer: new UserAuthorizer(data.currentUser.permissions)}}>
                                    <MainLayout>
                                        {privateRoutes}
                                    </MainLayout>
                                </UserContext.Provider>
                            }
                        </Init>
                    </AxiosContext.Provider>
                </ApolloProvider>
                :
                <ApolloProvider
                    client={
                        new ApolloClient({
                            link: errorLink.concat(
                                createUploadLink({
                                    uri: GRAPHQL_PUBLIC_ENDPOINT,
                                })
                            ),
                            cache: new InMemoryCache()
                        })
                    }
                >
                    <Publici18n>
                        {() =>
                            <React.Fragment>
                                {publicRoutes}
                            </React.Fragment>
                        }
                    </Publici18n>
                </ApolloProvider>
            }
            <Portal>
                <SnackMessage/>
            </Portal>
        </AuthContext.Provider>
    )
}

export default withRouter(App)
